import { get } from '../utils/request'
import apiRoot from '../_data/apiRoot'

class AccountReceivableAging {
  async getAccountReceivableAging() {
    var now = new Date()
    var month = now.getMonth() + 1
    var day = now.getDate()
    if (month < 10) month = '0' + month
    if (day < 10) day = '0' + day
    var today = `${now.getFullYear()}-${month}-${day}`
    var startDate = `${now.getFullYear()}-${month}-01`
    const endDate = today
    const response = await get(
      'report',
      `receivable-aging-report?start_date=${startDate}&end_date=${endDate}`,
      'GET',
    )
    return response
  }

  async getPDFURL(pdfUrl) {
    var apiRootValue = `${apiRoot['report']}`
    var pdf_url = apiRootValue.split('api/')[0] + pdfUrl.pdfUrl
    return pdf_url
  }
}
export default AccountReceivableAging
